const locationCombinationWithCategories = {
  all: [3],
  province: [3],
  city: [9, 2, 1],
  popular_city: [2, 4, 5, 7, 8, 9, 10, 11, 12, 13],
};

const labelsWithCityLinking = [5, 19, 8, 10, 55, 92];

const routeConstructor = ({
  name,
  adCount,
  category,
  subCategory,
  locationKey,
  slug: cityName,
}) => {
  const provinceName = name?.toLowerCase().replace(/ /g, '-');

  if (category) {
    const withInternalLinking =
      locationCombinationWithCategories[locationKey].includes(category.id) ||
      locationCombinationWithCategories.all.includes(category.id) ||
      labelsWithCityLinking.includes(subCategory?.id);

    const categoryLink = subCategory
      ? `${category.value_nl}/${subCategory.value_nl}`
      : category.value_nl;

    if (withInternalLinking) {
      if (locationKey === 'city') {
        if (
          !adCount ||
          (subCategory?.id && !labelsWithCityLinking.includes(subCategory?.id))
        ) {
          return {
            href: `/${categoryLink}?settlement=${cityName}`,
            pathname: `/${categoryLink}`,
            query: { settlement: cityName },
          };
        } else {
          return {
            href: `/${categoryLink}/${cityName}`,
          };
        }
      }

      if (locationKey === 'province') {
        if (
          !adCount ||
          (subCategory?.id && !labelsWithCityLinking.includes(subCategory?.id))
        ) {
          return {
            href: `/${categoryLink}?province=${provinceName}`,
            pathname: `/${categoryLink}`,
            query: { province: provinceName },
          };
        }
        return {
          href: `/${categoryLink}/provincie-${provinceName}`,
        };
      }
      if (locationKey === 'popular_city') {
        if (
          !adCount ||
          (subCategory?.id && !labelsWithCityLinking.includes(subCategory?.id))
        ) {
          return {
            href: `/${categoryLink}?settlement=${cityName.toLowerCase()}`,
            pathname: `/${categoryLink}`,
            query: { settlement: cityName },
          };
        } else {
          return {
            href: `/${categoryLink}/${cityName}`,
          };
        }
      }
    }

    if (!withInternalLinking) {
      const key = locationKey === 'province' ? 'province' : 'settlement';
      return {
        href: `/${categoryLink}?${key}=${cityName}`,
        pathname: `/${categoryLink}`,
        query: { [key]: cityName },
      };
    }
  } else {
    if (locationKey === 'city') {
      if (!adCount) {
        return {
          href: `/search?settlement=${cityName}`,
          pathname: `/search`,
          query: { settlement: cityName },
        };
      } else {
        return {
          href: `/${cityName}`,
        };
      }
    }

    if (locationKey === 'popular_city') {
      if (!adCount) {
        return {
          href: `/search?settlement=${cityName.toLowerCase()}`,
          pathname: `/search`,
          query: { settlement: cityName.toLowerCase() },
        };
      } else {
        return {
          href: `/${cityName}`,
        };
      }
    }

    if (locationKey === 'province') {
      if (!adCount) {
        return {
          href: `/search?province=${cityName}`,
          pathname: `/search`,
          query: { province: provinceName },
        };
      }
      return {
        href: `/provincie-${provinceName}`,
        pathname: `/search`,
        query: { province: provinceName },
      };
    }
  }
};

export default routeConstructor;
