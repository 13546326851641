import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import variablesJSS from 'static/styles/jss/abstracts/variables';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import variables from 'static/styles/jss/abstracts/variables';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import 'rmc-picker/assets/index.css';
import MobileTimePicker from 'rmc-picker/lib/Picker';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';
import { useTranslations } from 'next-intl';

import { SectionTitle } from 'Components/Shared/UI';

import { UiGenerateMargin, UIGetMarginLeftRight } from 'utils/handlers';

import { deviceParams } from 'store/reselect';

import { useAutoCompleteStyles, useFormControlStyles } from '../makeStylesUI';

import useStyles from './styles';
import {
  convertNaturalSize,
  getCurrentTimeOption,
  getSharedPropsOfClasses,
} from './config';

const canUseDOM = typeof window !== 'undefined';
React.useLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

const generateTime = time => ({
  hour: time.split(':')[0],
  minute: time.split(':')[1],
});
const TimePicker = props => {
  const t = useTranslations();
  const { deviceType } = useSelector(deviceParams());
  const [options, setOptions] = useState([]);
  const [mobileDate, setMobileDate] = useState({
    hour: null,
    minute: null,
  });
  const [isMobile, setIsMobile] = useState(false);
  const [allowTransition, setAllowTransition] = useState(false);
  const [disableBtn, setDisableBtn] = useState({
    prev: false,
    next: false,
  });
  const [time, setTime] = useState(null);
  const [marginBottom, setMarginBottom] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [propsOfStyles, setPropsOfStyles] = useState({});
  const [autocompleteValue, setAutocompleteValue] = useState({});
  const errorRef = useRef();
  const bottomWrapRef = useRef();
  const inputRef = useRef();
  const maskRef = useRef();
  const inputNowRef = useRef();
  const styles = useStyles(propsOfStyles);
  const isNotToday = props.isToday === false;

  // Get position of Main Wrapper
  const generateMarginDiv = useCallback(
    () => UiGenerateMargin(props.margin, props.direction),
    [props.direction, props.margin],
  );

  const sharedPropsOfClasses = getSharedPropsOfClasses({
    props,
    isOpen,
    isMobile,
    marginBottom,
    allowTransition,
  });

  const classesAutoComplete = useAutoCompleteStyles(sharedPropsOfClasses);

  const classesFormControl = useFormControlStyles({
    ...sharedPropsOfClasses,
    width: props.fullWidth
      ? `calc(100% - ${UIGetMarginLeftRight(props.margin)}px)`
      : convertNaturalSize(props.width, 'px'),
    size: props.size,
  });

  useEffect(() => {
    if (props.isToday && time && !props.needToHideNow) {
      setTime(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isToday]);

  useEffect(() => {
    const isDark = props.theme === 'dark';
    setPropsOfStyles({
      isDark,
      backgroundColor: isDark
        ? variablesJSS.$input.$theme.$dark
        : variablesJSS.$input.$theme.$light,
      actionsColor: isDark ? '#fff' : variables.$datepicker.$actionsColor,
      textColor: isDark ? '#fff' : '#000',
      selectedItem: isDark ? 'rgb(251 251 251 / 32%)' : '#BEBEBE',
      border: isDark ? '1px solid #11509F' : 'none',
      overlayBG: isDark ? 'rgb(255 243 243 / 30%)' : 'rgba(0,0,0, .5)',
    });
  }, [props.theme]);

  useEffect(() => {
    // Generate Options
    const getTime = {
      start: {
        ...generateTime(props.options.start),
      },
      end: {
        ...generateTime(props.options.end),
      },
    };
    let datePeriod = new Date();
    const dateEnd = new Date();
    datePeriod.setHours(getTime.start.hour);
    datePeriod.setMinutes(getTime.start.minute);
    datePeriod.setSeconds(0);
    dateEnd.setHours(getTime.end.hour);
    dateEnd.setMinutes(getTime.end.minute);
    dateEnd.setSeconds(0);

    const dates = [];
    while (!(datePeriod.getTime() > dateEnd.getTime())) {
      let _hour = `0${datePeriod.getHours()}`.slice(-2);
      if (
        datePeriod.getTime() >= dateEnd.getTime() &&
        getTime.end.hour === '24'
      ) {
        _hour = '24';
      }
      const option = `${_hour}:${`0${datePeriod.getMinutes()}`.slice(-2)}`;
      dates.push({ title: option, value: option });
      datePeriod = new Date(
        new Date(datePeriod).setMinutes(
          new Date(datePeriod).getMinutes() + +props.options.interval,
        ),
      );
    }
    setOptions(dates);
  }, [props.options]);

  useEffect(() => {
    // Detect if device is Mobile
    if (deviceType === 'mobile' || deviceType === 'tablet') {
      setIsMobile(true);
    } else setIsMobile(false);
  }, [deviceType]);

  useEffect(() => {
    // Calculate Margin Bottom
    const bottomElementHeight = errorRef.current
      ? errorRef.current.offsetHeight + 2
      : bottomWrapRef.current
      ? bottomWrapRef.current.offsetHeight + 2
      : 0;
    setMarginBottom(`${bottomElementHeight /* marginTopBottom */}px`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.required,
    props.errors,
    errorRef.current,
    props.helperText,
    bottomWrapRef.current,
  ]);

  useEffect(() => {
    isMobile && maskRef.current && maskRef.current.blur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, maskRef.current, isMobile]);

  useEffect(() => {
    // Disable the prev/next buttons
    if (time === null && props.value) {
      setTime(props.value.value);
      setAutocompleteValue({
        title: props.value.title,
        value: props.value.value,
      });
    }

    const value = time !== null ? time : props.value?.value || '';
    if (value) {
      if (isMobile)
        setMobileDate({
          hour: value.split(':')[0],
          minute: value.split(':')[1],
        });

      const findIndex = options.findIndex(e => e.value.includes(value));
      setDisableBtn({
        prev: findIndex <= 0 || (value && findIndex < 0),
        next: findIndex >= options.length - 1 || (value && findIndex < 0),
      });
    }
  }, [props.value, options, time, isMobile]);

  useEffect(() => {
    if (props.value) {
      const _value = typeof props.value === 'string';
      setAutocompleteValue({
        title: _value ? props.value : props.value.title,
        value: _value ? props.value : props.value.value,
      });
      setTime(_value ? props.value : props.value.value);
      if (isMobile)
        setMobileDate({
          hour: (_value ? props.value : props.value.title)?.split(':')[0],
          minute: (_value ? props.value : props.value.title)?.split(':')[1],
        });
    }
  }, [isMobile, props.value]);

  useEffect(() => {
    if (marginBottom) {
      setAllowTransition(true);
    }
  }, [marginBottom]);

  const inputMaskChange = evt => {
    setTime(evt.target.value);

    if (!evt.target.value) {
      setTimeout(() => inputNowRef.current?.focus(), 0);
    }
  };

  const inputBlur = e => {
    if (
      (time && !options.some(e => e.value === time)) ||
      (typeof time === 'string' && !time.length) ||
      (autocompleteValue && autocompleteValue.value !== time)
    ) {
      setTime(
        time?.length ? autocompleteValue.value : isNotToday ? '00:00' : null,
      );
      setAutocompleteValue({ value: '00:00' });
    }
    if (props.value && !e.target.value) {
      props.change({ value: '' });
    }
    !isMobile && setIsOpen(false);
  };

  const selectTime = useCallback(
    (_, { value }) => {
      setIsOpen(true);
      maskRef.current && maskRef.current.blur();
      setTime(value);
      setAutocompleteValue({
        value,
      });
      props.change({ value });
    },
    [props],
  );

  const selectMobileTime = () => {
    const _time = `${mobileDate.hour}:${mobileDate.minute}`;
    setTime(_time);
    setAutocompleteValue({
      title: _time,
      value: _time,
    });
    setIsOpen(false);
    props.change({ title: _time, value: _time });
  };

  const mobilePickerValueChange = (val, type) => {
    setMobileDate({
      hour: type === 'hour' ? val : mobileDate.hour,
      minute: type === 'minute' ? val : mobileDate.minute,
    });
  };

  const handleTimePickerClose = () => {
    setIsOpen(false);
    if (time) {
      setMobileDate({
        hour: time.split(':')[0],
        minute: time.split(':')[1],
      });
    }
  };

  useEffect(() => {
    if (isNotToday && !props.needToHideNow) {
      setTime('00:00');
      setAutocompleteValue({ value: '00:00' });
    }
  }, [isNotToday, props.needToHideNow]);

  const arrows = useMemo(() => {
    const changeDirection = (evt, dir) => {
      evt.stopPropagation();
      const currentIndex = options.findIndex(e => e.value === time);
      if (!disableBtn.prev && dir === 'prev') {
        const _value = time
          ? options[currentIndex > -1 ? currentIndex - 1 : options.length - 1]
              .value
          : getCurrentTimeOption(options, format(new Date(), 'HH:mm'));
        setTime(_value);
        setAutocompleteValue({
          title: _value,
          value: _value,
        });
        props.change({ title: _value, value: _value });
      } else if (!disableBtn.next && dir === 'next') {
        const _value = time
          ? options[currentIndex > -1 ? currentIndex + 1 : 0].value
          : getCurrentTimeOption(options, format(new Date(), 'HH:mm'), true);
        setTime(_value);
        setAutocompleteValue({
          title: _value,
          value: _value,
        });
        props.change({ title: _value, value: _value });
      }
    };
    return (
      <div className={'arrows-slide-datepicker'}>
        <svg
          onClick={e => changeDirection(e, 'prev')}
          className={`left-icon ${disableBtn.prev ? 'disable-btn' : ''}`}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
        <svg
          onClick={e => changeDirection(e, 'next')}
          className={`right-icon ${disableBtn.next ? 'disable-btn' : ''}`}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path d="M7 10l5 5 5-5z"></path>
        </svg>
      </div>
    );
  }, [disableBtn.prev, disableBtn.next, options, time, props]);

  const mobileDates = useMemo(
    () =>
      options.reduce((acc, item) => {
        const splitDate = item.title.replace(/ /g, '').split(':');
        acc[splitDate[0]] = [...(acc[splitDate[0]] || []), splitDate[1]];
        return acc;
      }, {}),
    [options],
  );

  const bottomWrap = useMemo(() => {
    if ((props.helperText || props.required) && !props.errors) {
      const text =
        props.helperText || (props.required ? t('required_field_text') : '');
      return (
        <div className={styles['bottom-wrap']} ref={bottomWrapRef}>
          {text && <p className="helper-text">{text}</p>}
        </div>
      );
    }
    if (props.errors)
      return (
        <span ref={errorRef} className={styles['error-line']}>
          {props.errors}
        </span>
      );
  }, [props.helperText, props.required, props.errors, styles, t]);

  return (
    <FormControl
      classes={{ root: classesFormControl.root }}
      style={{ ...generateMarginDiv() }}
    >
      {props.label && (
        <SectionTitle>
          {props.label.title + (props.required ? ' *' : '')}
        </SectionTitle>
      )}
      <div
        className={`mask-input ${isOpen ? 'focused-input' : ''} ${
          props.errors ? styles['error-field-picker'] : ''
        }`}
        data-disabled={props.disabled}
      >
        {time || (!time && isNotToday) || props.needToHideNow ? (
          <InputMask
            mask="99:99"
            onFocus={() => !isMobile && setIsOpen(true)}
            onBlur={inputBlur}
            value={time || ''}
            onChange={inputMaskChange}
            maskChar=""
          >
            {() => (
              <TextField
                onClick={() => isMobile && !props.disabled && setIsOpen(true)}
                InputProps={{
                  disabled: props.disabled,
                  startAdornment: (
                    <InputAdornment position="start">
                      <span className="icon-Time"></span>
                    </InputAdornment>
                  ),
                  endAdornment: props.slideByArrows ? (
                    <InputAdornment position="end">{arrows}</InputAdornment>
                  ) : null,
                }}
                inputRef={maskRef}
                variant="filled"
                focused={false}
                placeholder={props.placeholder}
              />
            )}
          </InputMask>
        ) : (
          <TextField
            onBlur={inputBlur}
            value={t('time_picker_now')}
            onClick={() => setIsOpen(true)}
            InputProps={{
              disabled: props.disabled,
              startAdornment: (
                <InputAdornment position="start">
                  <span className="icon-Time"></span>
                </InputAdornment>
              ),
              endAdornment: props.slideByArrows ? (
                <InputAdornment position="end">{arrows}</InputAdornment>
              ) : null,
            }}
            variant="filled"
            placeholder={props.placeholder}
            inputRef={inputNowRef}
          />
        )}
        {isMobile ? (
          <Dialog
            onClose={handleTimePickerClose}
            aria-labelledby="simple-dialog-title"
            open={isOpen}
            className={styles['timepicker-dialog']}
          >
            <div className={styles['mobile-wheel-picker']}>
              <h4>{t('update_ad_time')}</h4>
              <div className="pickers-wrapper">
                <MobileTimePicker
                  selectedValue={mobileDate.hour}
                  onValueChange={val => mobilePickerValueChange(val, 'hour')}
                >
                  {Object.keys(mobileDates)
                    .sort()
                    .map(hour => (
                      <MobileTimePicker.Item value={hour} key={hour}>
                        {hour}
                      </MobileTimePicker.Item>
                    ))}
                </MobileTimePicker>
                {mobileDate.hour ? (
                  <MobileTimePicker
                    selectedValue={mobileDate.minute}
                    onValueChange={val =>
                      mobilePickerValueChange(val, 'minute')
                    }
                  >
                    {mobileDates[mobileDate.hour]?.map(minute => (
                      <MobileTimePicker.Item value={minute} key={minute}>
                        {minute}
                      </MobileTimePicker.Item>
                    ))}
                  </MobileTimePicker>
                ) : (
                  ''
                )}
              </div>
              <div className="actions-wrap">
                <button onClick={handleTimePickerClose}>
                  {t('header_cancel_button')}
                </button>
                <button onClick={selectMobileTime}>
                  {t('profile_billings_filter_subtext_3')}
                </button>
              </div>
            </div>
          </Dialog>
        ) : (
          <Autocomplete
            disabled={props.disabled}
            disableCloseOnSelect={false}
            disableListWrap={false}
            classes={classesAutoComplete}
            options={options}
            getOptionSelected={option => option.value === time}
            renderOption={option => <div>{option.title}</div>}
            getOptionLabel={option => option.title}
            open={isOpen}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            onChange={selectTime}
            defaultValue={
              props.value
                ? { title: props.title || '', value: props.value || '' }
                : null
            }
            renderInput={params => (
              <TextField
                inputRef={inputRef}
                {...params}
                variant="filled"
                focused={false}
              />
            )}
          />
        )}
      </div>
      {bottomWrap}
    </FormControl>
  );
};
TimePicker.defaultProps = {
  size: 'md',
  fullWidth: false,
  margin: 0,
  required: false,
  theme: 'light',
  format: 'AM',
  slideByArrows: false,
  needToHideNow: true,
};
TimePicker.propTypes = {
  format: PropTypes.string,
  required: PropTypes.bool,
  slideByArrows: PropTypes.bool,
  theme: PropTypes.string,
  helperText: PropTypes.string,
  direction: PropTypes.string,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  label: PropTypes.object,
  options: PropTypes.shape({
    start: PropTypes.string,
    interval: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.string,
  change: PropTypes.func,
  margin: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
export default React.memo(TimePicker);
