import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import Select from '@material-ui/core/Select';
import variables from 'static/styles/jss/abstracts/variables';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useTranslations } from 'next-intl';

import { SectionTitle } from 'Components/Shared/UI';

import { UiGenerateMargin, UIGetMarginLeftRight } from 'utils/handlers';

import {
  useFormControlStyles,
  usePopoverStyles,
  useSelectStyles,
  useAutoCompleteStyles,
  useItemStyles,
} from '../makeStylesUI';

import useStyles from './styles';
import ListItem from './components/ListItem';

const BrandSelect = ({
  options,
  isInPlanner,
  label,
  value,
  placeholder,
  id,
  onOpen,
  isPricing,
  onChange,
  onClose,
  width,
  helperText,
  required,
  errors,
  size,
  defaultData,
  disabled,
  autocomplete,
  multiple,
  withIcon,
  withAvatar,
  customStylesInput,
  popoverZIndex,
  hiddenBottomWrapper,
  theme,
  readonly,
  paperMarginTop,
  readonlyNoIcon,
  fullWidth,
  margin,
  direction,
  className,
  rules,
  control,
  name,
}) => {
  const t = useTranslations();
  const [selectOpen, setSelectOpen] = useState(false);
  const [marginBottom, setMarginBottom] = useState('0px');
  const [allowTransition, setAllowTransition] = useState(false);
  const errorRef = useRef();
  const bottomWrapRef = useRef();
  const selectRef = useRef();
  const styles = useStyles();

  const sharedPropsOfClasses = useMemo(() => {
    const backgroundColor =
      theme === 'dark'
        ? variables.$input.$theme.$dark
        : variables.$input.$theme.$light;
    const boxShadow = errors
      ? `0 0 1px 1px ${variables.$danger} inset !important`
      : theme === 'dark'
      ? `${variables.$select.$dark.$borderColor} 0px 0px 0px 1px`
      : 'none';

    return {
      backgroundColor: readonly ? 'rgb(221, 217, 217)' : backgroundColor,
      boxShadow,
      transition: allowTransition
        ? 'all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)'
        : '0s',
      marginBottom,
      isOpened: selectOpen,
      isDark: theme === 'dark',
      listItemColor:
        theme === 'dark'
          ? variables.$select.$dark.$textColor
          : variables.$select.$textColor,
      selectedItemColor:
        theme === 'dark'
          ? variables.$select.$dark.$selected.$color
          : variables.$select.$selected.$color,
      selectedItemBG:
        theme === 'dark'
          ? variables.$select.$dark.$selected.$bgColor
          : variables.$select.$selected.$bgColor,
      placeholderColor:
        theme === 'dark'
          ? variables.$select.$dark.$emptyTextColor
          : variables.$select.$emptyTextColor,
      paperMarginTop,
      size,
    };
  }, [
    size,
    theme,
    selectOpen,
    readonly,
    marginBottom,
    paperMarginTop,
    allowTransition,
    errors,
  ]);

  const convertNaturalSize = useCallback((prop, what) => {
    if (Number.isNaN(+prop)) return prop;
    return prop + what;
  }, []);

  const togglePopover = val => {
    if (onOpen || onClose) {
      [val ? 'onOpen' : 'onClose'](val);
    }
    setSelectOpen(val);
  };

  const classesMenuItem = useItemStyles({
    size,
    iconColor: variables.$select.$dropdown.$listItem.$iconColor,
  });

  const classesFormControl = useFormControlStyles({
    ...sharedPropsOfClasses,
    width: fullWidth
      ? `calc(100% - ${UIGetMarginLeftRight(margin)}px)`
      : convertNaturalSize(width, 'px'),
    size,
  });

  const classesPopover = usePopoverStyles({
    zIndex: [popoverZIndex || 99999, '!important'],
    ...sharedPropsOfClasses,
    marginLeft: sharedPropsOfClasses.isDark ? '0px' : '0px',
  });

  const classesAutoComplete = useAutoCompleteStyles({
    ...sharedPropsOfClasses,
    textColor: sharedPropsOfClasses.isDark
      ? variables.$select.$dark.$textColor
      : variables.$select.$textColor,
    iconColor: sharedPropsOfClasses.isDark
      ? variables.$input.$theme.$darkColor
      : '#000',
  });

  const classesSelect = useSelectStyles({
    ...sharedPropsOfClasses,
    iconColor: sharedPropsOfClasses.isDark
      ? variables.$input.$theme.$darkColor
      : '#000',
    textColor: sharedPropsOfClasses.isDark
      ? variables.$select.$dark.$textColor
      : variables.$select.$textColor,
  });

  // Get position of Main Wrapper
  const generateMarginDiv = useCallback(
    () => UiGenerateMargin(margin, direction),
    [direction, margin],
  );

  const SelectProps = useMemo(
    () => ({
      ...(readonly && {
        IconComponent: () =>
          !readonlyNoIcon ? (
            <span className="icon-Lock select-custom-icon"></span>
          ) : null,
      }),
      disabled: disabled || readonly,
      value: /^[0-9]*$/gm.test(value) ? +value : value,
      displayEmpty: !!(!value && placeholder),
      renderValue: e => {
        if (placeholder && !value) {
          return <div className="default-value-empty">{placeholder}</div>;
        }

        let currentValue;
        if (isPricing) {
          currentValue = defaultData?.find(option => option.value === e);
        } else {
          currentValue = options.find(
            _e => (/^[0-9]*$/gm.test(_e.value) ? +_e.value : _e.value) === e,
          );
        }

        if (currentValue) {
          const hour = currentValue.value / 3600;
          return (
            <div className={withAvatar ? 'with-avatar-value-wrapper' : ''}>
              {withAvatar ? (
                <img src={currentValue.imgPath} alt="avatar-select" />
              ) : (
                ''
              )}
              {isInPlanner
                ? `${t('update_planner_every')} ${hour} ${t(
                    'update_planner_hour',
                  )}`
                : currentValue.title}
            </div>
          );
        }
      },
      ...(id && { id }),
    }),
    [options, value, withAvatar, id, readonly, readonlyNoIcon, placeholder],
  );

  const bottomWrap = useMemo(() => {
    if (hiddenBottomWrapper) return null;
    if ((helperText || required) && !errors) {
      const text = helperText || (required ? t('required_field_text') : '');
      return (
        <div className={styles['bottom-wrap']} ref={bottomWrapRef}>
          {text && <p className="helper-text">{text}</p>}
        </div>
      );
    }
    if (errors)
      return (
        <span ref={errorRef} className={styles['error-line']}>
          {errors}
        </span>
      );
    return null;
  }, [helperText, errors, errorRef, required, hiddenBottomWrapper]);

  const generateClassNameParent = useMemo(
    () =>
      `${disabled ? 'disable-select-wrapper' : ''} ${className} ${
        selectOpen ? 'select-opened' : ''
      }`,
    [disabled, className, selectOpen, errors],
  );

  useEffect(() => {
    // Calculate Margin Bottom
    const bottomElementHeight = errorRef.current
      ? errorRef.current.offsetHeight + 2
      : bottomWrapRef.current
      ? bottomWrapRef.current.offsetHeight + 2
      : 0;
    setMarginBottom(`${bottomElementHeight /* marginTopBottom */}px`);
  }, [required, errors, errorRef.current, helperText, bottomWrapRef.current]);

  useEffect(() => {
    if ((marginBottom !== '0px' && !allowTransition) || bottomWrap === null) {
      setAllowTransition(true);
    }
  }, [marginBottom, allowTransition, bottomWrap]);

  return (
    <FormControl
      error={!!errors}
      classes={{ root: classesFormControl.root }}
      disabled={disabled}
      className={generateClassNameParent}
      style={{ ...generateMarginDiv() }}
    >
      {label && (
        <SectionTitle styles={{ ...(errors && { color: '#FB2A2A' }) }}>
          {label.title + (required ? ' *' : '')}
        </SectionTitle>
      )}
      {autocomplete ? (
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          className={multiple ? 'MuiMultiple_Autocomplete_field' : ''}
          disableCloseOnSelect
          disableListWrap={false}
          classes={classesAutoComplete}
          options={options}
          style={{ ...customStylesInput }}
          getOptionSelected={(option, value) => option.title === value.title}
          renderOption={option => <div>{option.title}</div>}
          getOptionLabel={option => option.title}
          onOpen={() => togglePopover(true)}
          onClose={() => togglePopover(false)}
          control={control}
          rules={rules}
          onChange={onChange}
          name={name}
          renderInput={params => (
            <TextField
              {...params}
              variant="filled"
              focused={false}
              placeholder={placeholder}
            />
          )}
        />
      ) : (
        <Select
          ref={selectRef}
          control={control}
          rules={rules}
          onChange={onChange}
          name={name}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            PopoverClasses: {
              root: classesPopover.root,
            },
            classes: {
              paper: classesPopover.paper,
            },
          }}
          onOpen={() => togglePopover(true)}
          onClose={() => togglePopover(false)}
          className={`${classesSelect.select} select`}
          {...SelectProps}
          inputProps={{
            classes: {
              root: classesSelect.root,
              disabled: classesSelect.disabled,
              icon: classesSelect.icon,
            },
          }}
        >
          {options.length ? (
            options.map(item => (
              <MenuItem
                style={{ width: selectRef.current?.clientWidth }}
                value={item.value}
                key={item.value}
                disabled={item.disabled}
                classes={classesMenuItem}
              >
                <ListItem
                  isInPlanner={isInPlanner}
                  avatarBG={theme === 'dark' ? '#fff' : '#0B0E2A'}
                  avatarColor={
                    theme === 'dark' ? variables.$input.$theme.$dark : '#fff'
                  }
                  itemField={item}
                  withAvatar={withAvatar}
                  withIcon={withIcon}
                />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled classes={classesMenuItem}>
              No options
            </MenuItem>
          )}
        </Select>
      )}
      {bottomWrap}
    </FormControl>
  );
};
BrandSelect.defaultProps = {
  options: [],
  theme: 'light',
  size: 'md',
  margin: 0,
  multiple: false,
  withAvatar: false,
  withIcon: false,
  hiddenBottomWrapper: false,
  className: '',
  paperMarginTop: 0,
  customStylesInput: {},
};

BrandSelect.propTypes = {
  className: PropTypes.string,
  withAvatar: PropTypes.bool,
  withIcon: PropTypes.bool,
  multiple: PropTypes.bool,
  autocomplete: PropTypes.bool,
  theme: PropTypes.string,
  readonly: PropTypes.bool,
  readonlyNoIcon: PropTypes.bool,
  helperText: PropTypes.string,
  direction: PropTypes.string,
  required: PropTypes.bool,
  hiddenBottomWrapper: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.array,
  name: PropTypes.string,
  label: PropTypes.object,
  customStylesInput: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  paperMarginTop: PropTypes.number,
  popoverZIndex: PropTypes.number,
};
export default React.memo(BrandSelect);
