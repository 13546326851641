import Axios from 'axios';
import jwt_decode from 'jwt-decode';

import { ENV } from 'utils/constants';
import { Cookie } from 'utils/handlers';
import { getFromStorage, removeFromStorage } from 'utils/persist';
import { isObject } from 'utils/helpers';

import { store } from 'store/configureStore';
import { setErrorMessage } from 'store/actions';
import { getDictionaryErrors } from 'store/reselect';

import API from 'services/api';

const CLIENT_ERRORS_STATUS_CODES = [400, 404];
const AUTHORIZATION_STATUS_CODES = [401, 403];

const whiteList = [
  '/profile/configs',
  'media/configs',
  'media/public-images',
  'profile-verification/id/public',
  'profile-verification/person/public',
  'account/configs',
  '/account?create=1',
  'account/auth/tokens',
  'cms/url/validate',
  'account/auth/refresh',
  'payment/configs',
  'aggregator/available-filters',
  'aggregator/advertisements',
  '/account/passwords/recover',
  'dictionary/bulk',
  'aggregator/popular-categories',
  'profile-ad/unauthorized',
  '/profile-ad/configs',
  '/geography/configs',
  '/contact-verification/email',
  '/password/reset',
  '/dictionary',
  'statistic/advertisement/add-view',
  'aggregator/available-now',
  'aggregator/search/suggestions',
  'aggregator/highlighted-events',
  'aggregator/promotions',
  'inventory/categories',
  'aggregator/most-viewed',
  'aggregator/highlighted-vacancies',
  'aggregator/filters',
  'aggregator/popular-cities',
];

const axiosInstance = Axios.create({
  baseURL: ENV.baseUrl,
});

axiosInstance.all = function all(promises) {
  return Promise.all(promises);
};

axiosInstance.interceptors.request.use(
  async request => {
    let token = Cookie.getToken;
    if (typeof window !== 'undefined' && sessionStorage.accountId) {
      request.url += request.url.includes('?')
        ? `&account_id=${sessionStorage.accountId}`
        : `?account_id=${sessionStorage.accountId}`;
    }
    if (
      whiteList.every(e => request.url.indexOf(e) === -1) &&
      !request.url.includes('public')
    ) {
      if (token) {
        const { exp } = jwt_decode(token);
        if (Date.now() > exp * 1000) {
          const refreshToken = getFromStorage('refreshToken');

          const {
            data: { access_token },
          } = await API.getAuthRefreshToken(refreshToken);

          Cookie.setToken(access_token);
          token = access_token;
        }
      }
      request.headers.Authorization = `Bearer ${token}`;
    }
    return {
      ...request,
    };
  },
  err => err,
);

axiosInstance.interceptors.response.use(
  res => res,
  err => {
    let errMsg =
      (err.response?.data ? err.response.data.message : err.message) || '';
    if (typeof window !== 'undefined') {
      if (AUTHORIZATION_STATUS_CODES.includes(err.response?.status)) {
        Cookie.removeCookieByKey('token');
        removeFromStorage('refreshToken');
        sessionStorage.clear();
        errMsg = err.response.data.message;
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }

      if (CLIENT_ERRORS_STATUS_CODES.includes(err.response?.status)) {
        const currentErrorMessage = getDictionaryErrors(store.getState()).find(
          ({ err_message }) => err_message === errMsg,
        );

        errMsg = currentErrorMessage || {
          err_message: 'something_went_wrong',
          type: 'error',
        };
      }

      if (err.name === 'AbortError') {
        console.log('Request was aborted');
        return;
      }

      store?.dispatch(
        setErrorMessage(
          isObject(errMsg)
            ? errMsg
            : {
                err_message: 'something_went_wrong',
                type: 'error',
              },
        ),
      );
    }
    throw err;
  },
);

export default axiosInstance;
