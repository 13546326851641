import Types from '../types';

export const initialState = {
  accountProfiles: [],
  fetchingProfiles: null,
  user: {
    processLogin: false,
    verificationInfo: null,
  },
  loginByPopUp: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_ACCOUNT_PROFILES:
      return { ...state, accountProfiles: action.payload };
    case Types.FETCHING_PROFILES:
      return { ...state, fetchingProfiles: action.payload };
    case Types.SET_USER_DATA:
      return { ...state, user: { ...state.user, ...action.payload } };
    case Types.SET_LOGIN_BY_DIALOG:
      return { ...state, loginByPopUp: action.payload };
    case Types.ACCEPT_COOKIES:
      return { ...state, user: { ...state.user, cookies_accepted: true } };
    case Types.ADD_VERIFICATION_INFO:
      return {
        ...state,
        user: { ...state.user, verificationInfo: action.payload },
      };
    case Types.ACCEPT_RECOVER:
      return { ...state, user: { ...state.user, is_recovered: false } };
    default:
      return state;
  }
};

export default reducer;
