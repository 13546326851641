import { createSelector } from 'reselect';

import { _authState } from './selectors';

const authSelector = () => createSelector([_authState], items => items);

const accountSelector = () => createSelector([_authState], items => items.user);

export const getUser = store => _authState(store).user;

export const getVerificationInfo = store => getUser(store).verificationInfo;
export const getUserRole = store => getUser(store).role;
export const getUserLanguage = store => getUser(store).language;
export const getUserAccountId = store => getUser(store).id;
export const getAccountProfile = store =>
  _authState(store).accountProfiles || [];

const accountProfiles = () =>
  createSelector([_authState], items => items.accountProfiles || []);

const accountProfileById = id =>
  createSelector(
    [_authState],
    items => items.accountProfiles?.find(e => e._id === id) || {},
  );

const companyProfile = () =>
  createSelector([_authState], items =>
    (items.accountProfiles || []).find(e => e._meta?.type === 'company'),
  );

const companySubProfiles = () =>
  createSelector(
    [_authState],
    items =>
      (items.accountProfiles || []).filter(e => e._meta.type !== 'company') ||
      [],
  );

const getLoggedUserData = () =>
  createSelector([_authState], items => items.user || {});

const profilesFetchStatus = () =>
  createSelector([_authState], items => items.fetchingProfiles);

export const getProfilesFetchStatus = store =>
  _authState(store).fetchingProfiles;

const getProfileValuesForHeader = () =>
  createSelector([_authState], items => {
    if (items.accountProfiles.length) {
      if (items.user.role === 'single' || 'couple') {
        let profilePhotoId;
        if (items.accountProfiles[0].photo_id) {
          profilePhotoId = items.accountProfiles[0].photo_id;
        } else if (
          items.accountProfiles[0]._changes.fields.photo_id &&
          items.accountProfiles[0]._changes.fields.photo_id.length
        ) {
          profilePhotoId =
            items.accountProfiles[0]._changes.fields.photo_id[0].value.value;
        }
        return profilePhotoId || undefined;
      }
      return '';
    }
  });

export {
  authSelector,
  accountSelector,
  accountProfiles,
  companyProfile,
  accountProfileById,
  getLoggedUserData,
  profilesFetchStatus,
  companySubProfiles,
  getProfileValuesForHeader,
};
